.topItem {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5rem;
  padding: 1rem;
}
.accordian_styles {
  box-shadow: none !important;
}

.accordian_header {
  background: #08df73 !important;
  justify-content: center;
  box-shadow: none !important;
  height: 14px !important ;
}

.listItems {
  padding: 10px 10px 0;

  display: flex;
  align-items: center;
  float: inline-start;
}

.header_container {
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  justify-content: space-between;
}
.header_text {
  font-size: 12px;
  color: #808080;
}
.header_normal {
  color: black;
}
.header_dark {
  color: black;
  font-weight: 700;
}
.dataTable {
  font-family: 'Poppins', sans-serif;
  color: #546c5f;
  font-size: 16px;
  font-weight: 400;
  outline: none !important;
}
