.footerContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-height: 70vh;
  padding: 25px;
  /* background: linear-gradient(rgba(245, 245, 245, 0.15), rgba(245, 245, 245, 0.15)), url('/public/assets/images/footermobile.svg'); */
  background-color: #3c4d43;
  /* background-image: url('/public/assets/images/footermobile.svg'); */
  background-size: cover;
  background-repeat: no-repeat;
}
.brand_company{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  margin-top: -12px;
}
.primaryHeading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  color: #fff;
  text-transform: uppercase;
}
.social {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  /* padding: 1rem; */
  width: 47%;
  align-items: center;
  list-style: none;
  margin-left: -6px;
}
.socialListItems {
  height: 100%;
  display: flex;
  padding: 10px;
  cursor: pointer;
}
.inputName {
  width: 100%;
  /* font-weight: bold; */
  background-color: #3c4d43;
  padding: 11px;
  border: 1px solid #fff;
}
/* .inputName::placeholder {
  font-family: 'Cormorant', sans-serif;
  color: #716d6d;
} */
.newsletter {
  display: flex;
  margin-top: 2rem;
  padding: 0px;
  max-width: 550px;
  width: 100%;
  position: relative;
  margin-bottom: 2rem;
}
.subscribe {
  position: absolute;
  top: 41%;
  left: 45%;
  background: #fff;
  padding: 6px 17px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;
  color: #3c4d43;
}

.footerLinks {
  /* padding: 5px; */
  margin-top: 1.5rem;
  padding-left: 0;
  list-style: none;
}
/* .footerLinks li {
    /* display: block; */

.footerLinks li {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 2.5rem;
  color: #fff;
  text-decoration: none;
  margin-left: 1.2rem;
  text-align: justify;
}
.footerLinks p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 2.1rem;
  color: #fff;
  text-decoration: none;
  margin-left: 1.2rem;
  text-align: justify;
}
@media (min-width: 576px) {
  .subscribe {
    position: absolute;
    top: 37%;
    left: 119px;
    /* right: 1px; */
    padding: 6px 15px;
  }
}
@media (max-width: 768px) {
  .subscribe {
    top: 40%;
    left: 44%;
    /* right: 1px; */
    padding: 5px 11px;
  }
}
