.navContainer {
  width: 100%;
  height: 10vh;
  background-color: #3c4d43;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
    top: 0;
    z-index: 999;
}

.logoContainer {
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100%;
  flex-grow: 1;
  cursor: pointer;
}

.brand_company{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
    margin-top: -12px;
}

.searchInput {
  padding: 1rem 1.6rem;
  padding-left: 3rem;
  background-color: #3c4d43;
  outline: #fff;
  color: #fff;
  outline: 1px solid #fff;
  border: none;
  width: 100%;
}

.searchInput::placeholder {
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
}

.searchIcon {
  position: absolute;
  left: 2%;
  top: 22%;
  z-index: 2;
}

.searchContainer {
  position: relative;
  flex-grow: 1.5;
  margin-right: 3rem;
  margin-top: 1rem;
}

.navItem {
  font-size: 1.4rem;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  flex-grow: 1;
  align-self: center;
  cursor: pointer;
  margin: 1.8rem;
  position: relative;
}

.logoSmall {
  display: none;
}

.iconsContainer {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  gap: 2rem;
  list-style: none;
  position: relative;
}

.dropdown {
  background-color: #fff;
  /* position: absolute; */
  /* right: 0; */
  padding-left: 0;
  /* min-width: 250px; */
  /* padding: 2.5rem; */
  /* display: none; */
}

/* .dropdown ul li a {
  color: #546c5f;
  width: 180px;
} */
/* .dropdown:hover ul {
  display: block;
} */

.dropdownList {
  padding: 1rem;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #546c5f;
  text-decoration: none;
  list-style: none;

  /* min-width: 200px; */
  /* border-bottom: 1px sild #546c5f; */
}

.dropdownList:not(:last-child) {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
}

.profile {
  cursor: pointer;
}

.dropdownLists {
  padding: 1rem;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #546c5f;
  text-decoration: none;
  list-style: none;
}

.signup {
  list-style: none;
  margin-top: 16px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #3c4d43;
  margin-bottom: 10px;
  padding: 1.2rem
}

.headerButton {
  text-align: center;
  cursor: pointer;
  padding: .9rem 2rem;
  background-color: var(--primary-color);
  border: none;
  color: #fff;
  outline: none;
  margin-top: 1rem;
  font-weight: 400;
  font-size: 1.4rem;
  font-family: inherit;
  text-transform: capitalize;
}

.headerButton:hover {
  background-color: var(--primary-color);
}

.location {
  color: #fff;
  font-size: 1.3rem;
  margin-left: 3rem;
}

@media screen and (max-width: 900px) {
  .searchContainer {
    display: none;
  }

  .logoLarge {
    width: 15rem;
  }
}

@media screen and (max-width: 600px) {
  .logoSmall {
    display: inline;
  }

  .logoLarge {
    display: none;
  }

  .navItem {
    display: none;
  }

  .logoContainer {
    padding-left: 2rem;
    justify-content: flex-start;
  }

  .iconsContainer {
    justify-content: flex-end;
    padding-right: 2rem;
  }
  .location{
    display: none;
  }
}