.footerContainer {
  display: flex;
  flex-wrap: wrap;
  min-height: 100px;
  justify-content: space-between;
}

.leftContent {
  border-top: 1px solid #546c5f;
  flex: 0 0 40%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #546c5f;
}

.rightCorner {
  flex: 1;
  border-top: 1px solid #3c4d43;
}

.rightContent {
  border-top: 1px solid #546c5f;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 40vh;
  background-image: url('/public/assets/images/quizWatermark.png');
  opacity: 1;
  background-color: rgba(5, 24, 13, 0.90);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  font-family: 'Poppins';
  font-style: normal;
  font-size: 1.6rem;
  color: #fff;
}

.rightContentContainer {
  border-right: 1px solid #3c4d43;
  height: 45vh;
  margin-Right: 8rem;
}

.heading {
  font-weight: 500;
}

.contactLinks {
  width: 25vw;
}

h1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  text-transform: uppercase;
  margin-top: 2rem;
  color: #3c4d43;
}
.brand_company{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #3c4d43;
  margin-top: -12px;
}

.inputName {
  width: 57%;
  font-weight: bold;
  padding: 13px;
}

.inputName::placeholder {
  font-family: 'Cormorant', sans-serif;
  color: #716d6d;
}

.newsletter {
  margin-top: 2rem;
  padding: 0px;
  max-width: 550px;
  width: 100%;
  position: relative;
  margin-bottom: 2rem;
}

.subscribe {
  position: absolute;
  top: 14%;
  right: 45%;
  background: #3c4d43;
  padding: 8px 12px;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s;
}

.subscribe:hover {
  opacity: 0.6;
  transform: translateX(2px);
}

.footerLinks {
  /* padding: 5px; */
  margin-top: 1.5rem;
  padding-left: 0;
  list-style: none;
}

/* .footerLinks li {
  /* display: block; */

.footerLinks li {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 2.1rem;
  color: #fff;
  text-decoration: none;
  margin-left: 1.2rem;
  text-align: justify;
  cursor: pointer;
}

.footerLinks p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 2.1rem;
  color: #fff;
  text-decoration: none;
  margin-left: 1.2rem;
  text-align: justify;
}

.footerLinks a:active,
.footerLinks a:focus,
.footerLinks a:hover {
  text-decoration: none;
}

.social {
  margin-top: 1rem;
  background-color: #3c4d43;
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
}

.socialListItems {
  flex-grow: 1;
  height: 100%;
  border-right: 0.75px solid #546c5f;
  display: flex;
  padding: 10px;
  cursor: pointer;
}

.socialIcons {
  color: #fff;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  margin-left: 8px;
}

.ListItems {
  display: flex;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 2.1rem;
  color: #fff;
  text-decoration: none;
  /* margin: 1rem; */
}

.message {
  display: flex;
  flex-direction: column;
}

.boldText {
  color: #07794C;
  font-family: 'Playfair Display', serif;
  font-size: 4rem;
  margin-top: 1rem;
  font-weight: 900;
}

.modalContainer {
  background-color: #fff;
  min-width: 300px;
  width: 40vw;
  max-width: 500px;
  min-height: 450px;
  border: none;
  outline: none;
  padding: 2.5rem;
  background-size: cover;
  background-position: center;
}

.message {
  margin-top: 2.6rem;
  font-size: 1.6rem;
  width: 70%;
  text-align: center;
}

@media screen and (max-width: 1100px) {
  .modalContainer {
    min-width: 400px;
    width: 60vw;
    min-height: 400px;
  }

}

@media screen and (max-width: 950px) {

  .footerLinks p,
  .footerLinks a {
    font-size: 1rem;
  }

  .rightContentContainer {
    margin-right: 4rem;
  }
}

@media screen and (max-width: 600px) {
  .modalContainer {
    min-width: 300px;
    width: 90vw;
    min-height: 400px;
  }

  .message {
    margin-top: 1.6rem;
    font-size: 1.6rem;
    width: 90%;
    text-align: center;
  }

  .boldText {
    font-size: 3rem;
  }
}