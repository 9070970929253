.nocontent_wrapper {
  padding: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nocontent_wrapper .content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.nocontent_wrapper .content .image {
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
}

.nocontent_wrapper .content .image.small {
  max-width: 76px;
}

.nocontent_wrapper .content .image img {
  width: 100%;
  height: auto;
}

.nocontent_wrapper .content .title {
  font-size: 14px;
  font-weight: 600;
  color: #808080;
  margin-bottom: 3px;
}

.nocontent_wrapper .content .description {
  color: #808080;
  font-size: 12px;
  text-align: center;
}
