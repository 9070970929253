.container {
    width: 100%;
    height: 100%;
    background-position: start;
    background-size: 100% 100%;
}

.contentRow {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
}

.phoneInput {
    padding: 1rem;
    outline: 1px solid #fff;
    border: 1.6px solid #B3B3B3;
    margin-top: .5rem;
    width: 30rem;
    padding-left: 4rem;
}

.inputGroup {
    position: relative;
}

.prefix {
    position: absolute;
    top: 50%;
    transform: translate(0%, -42%);
    color: #B3B3B3;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    padding-left: .7rem;
    font-size: 1.3rem;
}

.phoneInput:invalid[focused="true"] {
    border: 1.6px solid#B32306;
}

.phoneInput::placeholder {
    color: #B3B3B3;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
}

.phoneInput::-webkit-outer-spin-button,
.phoneInput::-webkit-inner-spin-button {
    appearance: none;
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.phoneInput[type=number] {
    appearance: none;
    -moz-appearance: textfield;
}



.primaryHeading {
    text-transform: capitalize;
    font-size: 6rem;
    color: #3C4D43;
    font-family: 'Playfair Display', serif;
    font-weight: 900;
}

.registerHeading {
    font-size: 4rem;
    color: #3C4D43;
    font-family: 'Playfair Display', serif;
    font-weight: 900;
    text-align: center;
    margin-top: 4rem;
}



.description {
    margin-top: 3.5rem;
    font-size: 1.7rem;
    color: #3C4D43;
    width: 70%;
    text-align: center;
    line-height: 1.6;
    font-family: 'Poppins', sans-serif;
}

.error {
    font-size: 1.2rem;
    text-align: center;
    color: #B32306;
    background-color: #FCEEED;
    padding: .3rem 1.5rem;
    align-self: center;
}

.errorPhone {
    display: none;
    margin-top: 1rem;
}

.errorOtp {
    margin-bottom: 1rem;
}

.whiteContainer {
    position: relative;
    width: 45%;
    min-height: 85%;
    background-color: #fff;
    border-radius: 2px;
}


.inputGroup:has(.phoneInput:invalid[focused="true"])~.errorPhone {
    display: inline-block;
}

.submitButton {
    z-index: 4;
    cursor: pointer;
    padding: 1.1rem 1.6rem;
    width: 30rem;
    background-color: var(--primary-color);
    margin-top: 1.4rem;
    border: none;
    color: #fff;
    outline: none;
    font-weight: 400;
    font-size: 1.4rem;
    font-family: 'Poppins', sans-serif
}

.otpInput {
    width: 5rem;
    height: 5rem;
    padding: 1rem;
    text-align: center;
    border: none;
    outline: 2px solid var(--primary-color);
    color: var(--primary-color);
    font-weight: 500;
    font-size: 1.6rem;
}

.otpInput:focus::placeholder {
    color: transparent;
}

.leafDecor {
    position: absolute;
    right: 0;
    top: 10%;
}

.inputField {
    padding: 1rem;
    outline: 1px solid #fff;
    border: 1.6px solid #B3B3B3;
    margin-top: .5rem;
}

.registerLabel {
    display: flex;
    flex-direction: column;
    color: #4D4D4D;
    font-size: .95rem;
}

.saveButton {
    z-index: 4;
    cursor: pointer;
    padding: 1.1rem 1.6rem;
    background-color: var(--primary-color);
    margin-top: 3rem;
    border: none;
    color: #fff;
    outline: none;
    font-weight: 400;
    font-size: 1.4rem;
    font-family: 'Poppins', sans-serif
}

.saveButton:hover {
    background-color: var(--primary-color);
    transform: scale(1.01);
}

.headerButton {
    cursor: pointer;
    padding: 1.1rem 12rem;
    background-color: var(--primary-color);
    border: none;
    color: #fff;
    outline: none;
    margin-top: 1.6rem;
    font-weight: 400;
    font-size: 1.4rem;
    font-family: inherit;
}

.headerButton:hover {
    background-color: var(--primary-color);
}

.headerButton:disabled{
    color:#fff;
    background-color: #546c5f79;
}


@media screen and (max-width: 1100px) {
    .container {
        background-size: cover;
    }

    .whiteContainer {
        width: 70%;
    }
}


@media screen and (max-width: 900px) {

    .container {
        background-size: cover;
    }

    .searchContainer {
        display: none;
    }

    .whiteContainer {
        width: 80%;
    }


}

@media screen and (max-width: 600px) {


    .whiteContainer {
        width: 100%;
        height: 100%;
    }

    .leafDecor {
        width: 8rem
    }

    .description {
        font-size: 1.4rem;
    }

    .registerLabel {
        width: 80%;
        margin: 0 auto;
    }

    .inputField {
        width: 100%;

    }


}