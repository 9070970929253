.modal {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalButtonContainer {
    width: 65%;
    margin: 0 auto;
    margin-top: 4rem;
    display: flex;
    justify-content: space-between;
}

.modalButtonContainer button {
    width: 48%;
    padding-top: .8rem;
    padding-bottom: .8rem;
}

.modalContainerHeading {
    color: #3C4D43;
    font-weight: bold;
    font-size: 2.6rem;
    width: 70%;
    text-align: center;
    margin: 2rem auto;

}

.modalContainer {
    background-color: #fff;
    min-width: 200px;
    width: 40vw;
    max-width: 500px;
    min-height: 400px;
    border: none;
    outline: none;
    padding: 2.5rem;
    background-size: cover;
    background-position: center;
}

.closeButton {
    display: flex;
    justify-content: flex-end;
}

.closeButton img {
    cursor: pointer;
}

@media screen and (max-width: 900px) {
    .modalContainer {
        width: 80vw;
    }
}

@media screen and (max-width: 600px) {
    .modalButtonContainer {
        width: 90%
    }

    .modalTextField {
        width: 90%;
    }

    .modalContainer p {
        width: 80%;
        font-size: 2rem;
    }
}