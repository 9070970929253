.homeButton {
    cursor: pointer;
    padding: 1.1rem 3.6rem;
    background-color: var(--primary-color);
    border: none;
    color: #fff;
    outline: none;
    margin-top: 1rem;
    font-weight: 400;
    font-size: 1.4rem;
    font-family: inherit;
    margin-top: 4rem;
  }

  .homeButton:hover {
    background-color: var(--primary-color);
  }